.react-html5-camera-photo {
    height: 100% !important;
    position: fixed;
}

.react-html5-camera-photo > video {
    object-fit: cover !important;
    height: 100% !important;
}

.react-html5-camera-photo > img,
.react-html5-camera-photo > video {
    height: 100%;
    width: 100%;
}

img {
    object-fit: cover !important;
}

.sc-main-container {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.sc-overlay-top {
    position: fixed;
    width: 100%;
    height: 65%;
    top: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.sc-overlay-bottom {
    position: fixed;
    width: 100%;
    height: 35%;
    top: 65%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
}

.sc-top-section {
    width: 100%;
    padding-top: 80px;
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.6);
}

.sc-artifact-layer {
    width: 100%;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    margin-top: -20px;
    padding-left: 5%;
    padding-right: 5%;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
}

.sc-cameraIndicator {
    flex: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 0;
    margin: 0;
}

.sc-cameraIndicator.expand {
    flex: 1;
    border: 3pt solid rgba(255, 255, 255, 0.45);
    margin: 1% 5%;
}

.sc-image {
    max-width: 88%;
    margin-top: 10px;
    margin-bottom: 10px;
    z-index: 55;
}

.sc-button {
    width: 90%;
    height: 15%;
    margin-top: 4pt;
    background-color: rgba(255, 255, 255, 0.85);
    padding-left: 20pt;
    padding-right: 20pt;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.sc-button.collapsed {
    height: 0;
    visibility: hidden;
}

.sc-button-text {
    font-family: Avenir-Medium, monospace;
    letter-spacing: -0.61pt;
    font-size: 15pt;
    line-height: 15pt;
    color: rgb(0, 0, 0);
    z-index: 55;
}

.outer-circle {
    visibility: hidden;
}

.outer-circle.expand {
    visibility: visible;
}

.sc-title-text,
.sc-desc-text {
    font-family: Avenir-Medium, monospace;
    color: #fff;
    text-align: center;
    position: relative;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
}

.sc-desc-text {
    margin-bottom: 5px;
}

::placeholder {
    font-family: Avenir-Medium, monospace;
    letter-spacing: -0.61pt;
    font-size: 15pt;
    line-height: 15pt;
    color: rgb(0, 0, 0);
    line-height: 1.5em;
}

input {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    padding-left: 0;
}

input::-webkit-input-placeholder {
    line-height: 1.5em;
}

#container-circles {
    display: none;
}

.react-html5-camera-photo {
    position: fixed;
    text-align: center;
}

.react-html5-camera-photo > video {
    width: 768px;
}

@media (max-width: 768px) {
    .react-html5-camera-photo > video {
        width: 100%;
    }
}

/* fullscreen enable by props */
.react-html5-camera-photo-fullscreen > video {
    width: 100vw;
    height: 100vh;
}
.react-html5-camera-photo-fullscreen > video {
    object-fit: fill;
}

.sc-layer {
    position: fixed;
    z-index: 100;
}

.sc-enter-desc-text {
    padding: 15px;
    color: white;
    text-align: center;
}

.sc-enter-desc-container {
    width: 100%;
    padding: 0 5%;
}

.sc-button-1 {
    height: 28%;
    margin-top: 4pt;
    background-color: rgba(255, 255, 255, 0.85);
    padding-left: 20pt;
    padding-right: 20pt;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.sc-enter-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.sc-toogle-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    margin-top: 80px;
}

.sc-scan-toggle {
    width: 137px;
    height: 33px;
    border-radius: 17px;
    border: solid 1px #f7f7f7;
    display: flex;
    color: #f7f7f7;
    justify-content: center;
    align-items: center;
}

.sc-enter-container {
    flex: 1 1;
    justify-content: end;
    background-color: rgba(0, 0, 0, 0.6);
}

.sc-text-field-container {
    padding: 2px;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: white;
}

.sc-text-field-container > input {
    border: none;
    padding-left: 0;
    border-bottom: 1px solid;
    margin-bottom: 5px;
}

.sc-text-field-container > label,
.sc-text-field-container > input::placeholder {
    color: rgba(0, 0, 0, 0.56);
}

.sc-text-field-container > input::placeholder {
    font-size: 16px;
    font-weight: normal;
}

.sc-text-field-container > input {
    font-size: 16px;
    font-weight: 900;
}

.sc-enter-btn {
    width: 100%;
}

.sc-camera-not-working-btn {
    color: #00a862;
    text-decoration: underline;
}

.sc-camera-not-working-btn:hover {
    cursor: pointer;
}

@media only screen and (orientation: landscape) {
    .sc-overlay-top {
        position: fixed;
        width: 100%;
        height: 70%;
        top: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .sc-overlay-bottom {
        position: fixed;
        width: 100%;
        height: 30%;
        top: 70%;
        background-color: rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: center;
        padding-left: 5%;
        padding-right: 5%;
    }

    .sc-top-section {
        width: 100%;
        padding-top: 40px;
        position: relative;
        display: flex;
        flex: 1;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.6);
    }

    .sc-artifact-layer {
        width: 100%;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
        margin-top: -50px;
        padding-left: 5%;
        padding-right: 5%;
        bottom: 0;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-end;
    }

    .sc-cameraIndicator {
        flex: 0;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        border: 0;
        margin: 0;
    }

    .sc-cameraIndicator.expand {
        flex: 1;
        border: 3pt solid rgba(255, 255, 255, 0.45);
        margin: 1% 5%;
        width: 50%;
        align-self: center;
    }

    .sc-toogle-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        margin-top: 20px;
    }

    .sc-enter-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
}
