.background {
    background-repeat: repeat-y;
    /* Repeat the image vertically */
    /* background-size: cover; Ensure the image covers the width */
    min-height: 100vh;
    /* Make sure it covers at least the full height of the viewport */
    width: 100%;
    /* full width of the container */
}

.luna {
    background-image: url('https://treaceabilitycdn.azureedge.net/images/Milano/LunaBackground.png');
}

.sole {
    background-image: url('https://treaceabilitycdn.azureedge.net/images/Milano/SoleBackground.png');
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full height to center the spinner */
    background-color: #fff;
}

.loading-spinner img {
    width: 100px;
    height: auto;
}

.content {
    display: none;
}

.origin-and-farmers {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    /* Ensure both children take up full height */
}

.origin-bar {
    flex: 1;
    /* Allow it to take up space */
    height: 100%;
    /* Stretch the origin bar to match the height of the container */
    background-color: #f5f5f5;
    /* Optional: Add a background color to see the full bar */
}



.story-container {
    /* This ensures the farmer profiles have a fixed or minimum height */
    min-height: 300px;
    /* Adjust as needed */
}

.cbg-container-1 {
    display: flex;
    padding-left: 1%;
}

.cbg-style-for-cup-1 {
    width: 50px;
    height: 100%;
}

.cbg-percentage-container-1 {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.cbg-percentage-top {
    transform: translateY(-83%);
}

.cbg-percentage-bottom {
    transform: translateY(83%);
}
