.table {
    width: 100%;
}

.table > tbody > tr {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
}

.table > tbody > tr > td {
    padding: 0;
    width: 40%;
}

.td-regions {
    display: flex;
    flex-direction: column;
}

.title {
    font-weight: bold;
}

.text-caps {
    text-transform: capitalize;
}

.decaf-container {
    background-color: rgb(237, 235, 233);
    margin-bottom: 10px;
}

.decaf-content {
    background-color: rgb(237, 235, 233);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.62;
    letter-spacing: 0.17px;
    text-align: left;
}

.decaf-link {
    color: #00653e;
}

.product-section-container {
    padding: 15px;
}

.mt-15 {
    margin-top: 15px;
}
