.cbg-container {
    width: 100%;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    background-color: #f7f7f7 !important;
    align-items: center;
    position: relative;
}

.cbg-barContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.cbg-dataContainer {
    align-items: center;
    justify-content: center;
    flex: 1.2;
    margin-right: 20px;
    margin-left: 15px;
}

.cbg-singleData {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    align-items: center;
}
.cbg-singleData:first-of-type  {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.cbg-pointer {
    width: 12px;
    height: 12px;
    margin-top: 5px;
}

.cbg-title {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    width: 98%;
}

.cbg-style-for-cup {
    height: 280px;
    width: 80%;
    position: absolute;
    padding-left: 2px;
    padding-right: 2px;
}

.cbg-percentage-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 307px;
    align-items: flex-end;
}

.cbg-style-for-bag {
    top: 21%;
    height: 73%;
    width: 70%;
    position: absolute;
    padding-left: 2px;
    padding-right: 2px;
}
