.carousel__sliderItem.gridItem.size4of12 {
    width: 40%;
    margin: 5px;
    min-width: 150px;
}

.coffee-production-title {
    font-size: 12px;
    font-weight: 300;
}

.product-detail-carousel-image-container {
    display: flex;
    justify-content: center;
    padding: 15px;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    background-color: #1f3933;
}

.country-carousel-image-container {
    width: 150px;
    height: 150px;
}

.product-list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.product-list-container > * {
    flex: 0 150px;
}

.product-list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 1px;
}

.carousel-container {
    height: 180px;
}
