#root {
    height: 100%;
}

#root > div {
    height: 100%;
}

.sb-card__imageBlockSmall {
    max-width: 21% !important;
    min-width: 115px !important;
}

@media (max-width: 1024px) {
    .mainContentArea {
        padding-top: 0 !important;
    }
}

@media (min-width: 1024px) {
    .mainContentArea {
        padding-top: 20px !important;
    }
}

@media (min-width: 768px) {
    .sb-card__imageBlockSmall {
        max-width: 30% !important;
    }
}

@media (min-width: 768px) {
    .app-containerArea {
        max-width: 700px !important;
    }
}

.mainBodyArea {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.sb-mask {
    opacity: 0.6 !important;
}

.text-xxxl {
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.14px;
    text-align: left;
}

.heading-xl {
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.14px;
    text-align: left;
}

.text-xxl {
    font-size: 27px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.14px;
    text-align: left;
}

.heading-lg {
    font-size: 27px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: -0.14px;
    text-align: left;
}

.text-xl {
    font-size: 23px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.14px;
    text-align: left;
}

.heading-md {
    font-size: 23px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: -0.14px;
    text-align: left;
}

.text-lg {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.14px;
    text-align: left;
}

.heading-sm {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.26;
    letter-spacing: -0.14px;
    text-align: left;
}

.text-md {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.14px;
    text-align: left;
}

.heading-xs {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: -0.14px;
    text-align: left;
}

.text-sm {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.14px;
    text-align: left;
}

.text-xs {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.14px;
    text-align: left;
}

.heading-xxs {
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: 1.3px;
    text-align: left;
}

.text-xxs {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.14px;
    text-align: left;
}

.text-xxxs {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.11px;
    text-align: left;
}
