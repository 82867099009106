.camera-error-page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 50px;
}

.camera-error-page-header {
    position: relative;
    text-align: center;
    color: white;
}

.camera-error-page-header-text {
    position: relative;
    text-align: left;
    bottom: 15px;
    left: -2px;
    height: 33px;
    font-family: Avenir;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.38;
    letter-spacing: -0.82px;
    color: rgba(0, 0, 0, 0.9);
}

.camera-error-page-text-container {
    padding: 20px 15px;
}

.camera-error-page-container .error-page-text-desc {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0.21px;
    text-align: left;
    margin-bottom: 25px;
}

.camera-error-page-text-title {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.38;
    letter-spacing: -0.54px;
    text-align: left;
    color: rgba(0, 0, 0, 0.9);
    margin-top: 20px;
    margin-bottom: 20px;
}

.camera-error-page-container .error-page-text {
    font-size: 18px;
    line-height: 28px;
}

.camera-error-page-container .error-page-img-container {
    text-align: center;
    margin: 30px;
}

.camera-error-text-list {
    list-style-type: disc;
    margin-left: 20px;
}

.camera-error-text-list li {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: 0.21px;
    text-align: left;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 20px;
}

.camera-error-question-text {
    font-weight: 900;
}

.camera-error-trace-again-button-container {
    display: flex;
    justify-content: space-evenly;
    height: 32px;
}

.button-shadow {
    width: 170px;
    box-shadow: 0.1pt 0.1pt 2pt 2pt rgba(0, 0, 0, 0.1) !important;
}
