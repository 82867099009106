.sb-social-share {
    margin-left: 0;
    right: 0;
}

.sb-social-share__wrapper {
    display: block;
    position: relative;
    width: 4rem;
}

.sb-social-share__button {
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    border: 2px solid transparent;
    border-radius: 50%;
    -webkit-box-shadow: 0 10px 20px rgba(0,0,0,.15);
    box-shadow: 0 10px 20px rgba(0,0,0,.15);
    color: #008248;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    font-size: 1rem;
    height: 4rem;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    width: 4rem;
}

.screen-reader-text {
    clip: rect(1px,1px,1px,1px);
    height: 1px;
    overflow: hidden;
    position: absolute!important;
    width: 1px;
}

.sb-social-share__button .sb-icon {
    height: 3em;
    width: 3em;
}
.sb-icon {
    display: block;
    fill: currentColor;
    height: 1em;
    width: 1em;
}

.article-sticky-social.-viewed .sb-social-share__list {
    -webkit-animation: opacity-animation 4s;
    animation: opacity-animation 4s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}
.sb-social-share--open .sb-social-share__list {
    opacity: 1;
    pointer-events: auto;
}

.sb-social-share__list {
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    border-radius: .8rem;
    bottom: calc(100% + 2.5rem);
    -webkit-box-shadow: 0 10px 35px rgba(0,0,0,.1);
    box-shadow: 0 10px 35px rgba(0,0,0,.1);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    font-size: 2.8rem;
    list-style: none;
    margin: 0;
    opacity: 0;
    padding: 1rem .8rem 1.3rem;
    position: absolute;
    right: -2px;
    -webkit-transition: opacity .35s;
    transition: opacity .35s;
    /* z-index: -1; */
    pointer-events: none;
}

.article-sticky-social.-viewed .sb-social-share__item:first-child, .sb-social-share--open .sb-social-share__item:first-child {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition-delay: 75ms;
    transition-delay: 75ms;
}

.article-sticky-social.-viewed .sb-social-share__item, .sb-social-share--open .sb-social-share__item {
    -webkit-transition: opacity .35s linear,color .2s ease-out,color .2s ease-out,-webkit-transform .5s cubic-bezier(.22,.89,.57,1);
    transition: opacity .35s linear,color .2s ease-out,color .2s ease-out,-webkit-transform .5s cubic-bezier(.22,.89,.57,1);
    transition: opacity .35s linear,transform .5s cubic-bezier(.22,.89,.57,1),color .2s ease-out,color .2s ease-out;
    transition: opacity .35s linear,transform .5s cubic-bezier(.22,.89,.57,1),color .2s ease-out,color .2s ease-out,-webkit-transform .5s cubic-bezier(.22,.89,.57,1);
}

.sb-social-share__item {
    display: block;
    opacity: 0;
    -webkit-transform: translateY(75%);
    transform: translateY(75%);
    -webkit-transition: opacity 0s linear .35s,color .2s ease-out,-webkit-transform 0s .35s;
    transition: opacity 0s linear .35s,color .2s ease-out,-webkit-transform 0s .35s;
    transition: opacity 0s linear .35s,transform 0s .35s,color .2s ease-out;
    transition: opacity 0s linear .35s,transform 0s .35s,color .2s ease-out,-webkit-transform 0s .35s;
}

.sb-social-share__link--facebook {
    color: #507cbe !important;
}
.sb-social-share__link {
    color: #008248;
    cursor: pointer;
    display: block;
    outline: none;
    padding: .6rem 0 1rem;
    -webkit-transition: color .2s ease-out;
    transition: color .2s ease-out;
}

.article-sticky-social.-viewed .sb-social-share__item:nth-child(2), .sb-social-share--open .sb-social-share__item:nth-child(2) {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition-delay: .15s;
    transition-delay: .15s;
}

.sb-social-share__link--twitter {
    color: #63cdf1!important;
}

.sb-social-share__link .sb-icon {
    vertical-align: middle;
}
.sb-icon {
    display: block;
    fill: currentColor;
    height: 1em;
    width: 1em;
}

.screen-reader-text {
    clip: rect(1px,1px,1px,1px);
    height: 1px;
    overflow: hidden;
    position: absolute!important;
    width: 1px;
}

.article-sticky-social.-viewed .sb-social-share__item:nth-child(3), .sb-social-share--open .sb-social-share__item:nth-child(3) {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition-delay: 225ms;
    transition-delay: 225ms;
}

.sb-social-share__link--linkedin {
    color: #0077b5 !important;
}

.article-sticky-social.-viewed .sb-social-share__item:nth-child(4), .sb-social-share--open .sb-social-share__item:nth-child(4) {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition-delay: .3s;
    transition-delay: .3s;
}

.sb-social-share__link {
    color: #008248;
    cursor: pointer;
    display: block;
    outline: none;
    padding: .6rem 0 1rem !important;
    -webkit-transition: color .2s ease-out;
    transition: color .2s ease-out;
} 
.sb-social-share__list:after {
    border: 1rem solid transparent;
    border-top-color: #fff;
    content: "";
    height: 0;
    position: absolute;
    right: 1.25rem;
    top: 100%;
    width: 0;
}


@media (min-width: 768px) {
    .sb-social-share__wrapper {
        width: 6rem;
    }

    .sb-social-share__button {
        height: 6rem;
        width: 6rem;
    }
    
    .sb-social-share__list {
        padding: 1.5rem 1.7rem 1.8rem;
        right: 0;
    }

    .sb-social-share__list:after {
        right: 2rem;
    }
}
