.videoOverlay {
    background: rgba(67, 67, 67, 0.7);
}

.card-container {
    width: 80%;
    padding-bottom: 10px;
}

.carousel-card {
    height: 292px;
    display: flex;
    flex-direction: column;
}

.carousel-btn {
    margin-top: auto;
    display: flex;
    justify-content: center;
    height: auto;
}

.react-responsive-modal-closeButton {
    display: none;
}
