.hm-cafe-practice-container {
    padding: 30px 40px;
    text-align: center;
    background-color: #edebe9;
    margin: -10px;
}

.hm-cafe-practice-star {
    margin-right: 11pt;
    width: 110px;
}

.hm-title {
    font-family: Avenir-Heavy, sans-serif !important;
    font-size: 17pt !important;
    letter-spacing: -0.61pt !important;
    color: rgb(0, 0, 0) !important;
    line-height: 25pt !important;
    text-align: center !important;
}

.hm-description {
    font-family: Avenir-Light, sans-serif !important;
    font-size: 12pt !important;
    letter-spacing: 0.17pt !important;
    color: rgb(0, 0, 0) !important;
    line-height: 23pt !important;
    text-align: center !important;
}

.hm-button-center {
    width: 100%;
    margin-bottom: 52px;
}

.hm-coffee-academy-link {
    color: #00653e;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
}

.hm-social-share {
    max-width: 12%;
    margin-left: auto;
    margin-right: 10px;
}
