.bc-column {
    float: left !important;
    height: 20pt !important; /* Should be removed. Only for demonstration */
}

.bc-right {
    background-color: rgb(247, 247, 247);
}

.bc-titleContainer {
    width: 85%;
    padding-top: 3pt;
}

.bc-barContainer {
    width: 62% !important;
}

.bc-percentContainer {
    width: 13% !important;
    padding-top: 3pt;
}

.bc-arrowContainer {
    width: 15%;
    padding-top: 4pt;
}

/* Clear floats after the columns */
.bc-row:after {
    display: table !important;
    width: 100%;
}

.bc-percentText {
    font-family: Avenir-Light, sans-serif !important;
    font-size: 13pt !important;
    letter-spacing: -0.44pt !important;
    color: rgb(45, 41, 38) !important;
    line-height: 18pt !important;
    text-align: left !important;
}

.bc-titleText {
    font-family: Avenir-Light, sans-serif !important;
    font-size: 13pt !important;
    letter-spacing: -0.44pt !important;
    color: rgb(45, 41, 38) !important;
    line-height: 18pt !important;
}

