.country-list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.country-list-container > * {
    flex: 0 160px;
}

.country-list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 1px;
}
