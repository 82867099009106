@media (max-width: 1024px) {
.fixedHeader {
    position: fixed !important;
    top: 0px;
    width: 100%;
    z-index: 2;
   
    
}
}
@media (min-width: 1024px) {
    .fixedHeader {
      width:40%;  
        
    }
    }