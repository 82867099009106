.story-container {
    position: relative;
}

.expand-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 25px;
}

.profile-image-container {
    height: 350px;
}

.profile-image {
    height: 100%;
    width: auto;
}

.profile-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
