.ih-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: rgb(237, 235, 233) !important;
    padding: 16px 5px 16px 0;
    align-items: center;
}

.ih-imgContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 125px;
}

.ih-titleText {
    font-family: Avenir-Heavy, sans-serif !important;
    font-size: 15pt !important;
    color: rgb(45, 41, 38) !important;
    line-height: 22pt !important;
    text-align: left !important;
}

.ih-lotIdText {
    font-family: Avenir-Light, sans-serif !important;
    font-size: 15pt !important;
    color: rgb(45, 41, 38) !important;
    text-align: left !important;
}

.ih-subText {
    font-family: Avenir-Light, sans-serif !important;
    font-size: 13pt !important;
    letter-spacing: 0.17pt !important;
    color: rgb(45, 41, 38) !important;
    line-height: 18pt !important;
    text-align: left !important;
}

.ih-card-content-center {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.ih-description-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: rgb(237, 235, 233) !important;
    align-items: center;
}
