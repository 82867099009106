/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 28px, $lineCount: 1){
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  padding-right: 1em;
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
  }
}

.block-with-text {
  @include multiLineEllipsis($lineHeight: 28px, $lineCount: 3);
}
