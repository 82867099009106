.coffee-notes{  
    color: white;
    background-color: #00663E;
    text-align: center;
    padding: 5px

}

.blonde-seperator {
    height: 20px;
    background-color: #ddb459;
}

.medium-seperator {
    height: 20px;
    background-color: #aa683d;
}

.dark-seperator {
    height: 20px;
    background-color: #76435c;
}

.limited-seperator {
    height: 20px;
    background-color: #e3dedd;
}

.banner-image {
    display: block;
    margin: 0;
    padding: 0;
    border: none;
  }
