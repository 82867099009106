.ts_messageContainer {
    background-color: rgb(241, 238, 231);
    position: relative;
    left: 0;
    width: 100%;
    transition: top 500ms cubic-bezier(0, 0.04, 1, 1);
    overflow: hidden;
    box-sizing: border-box;
}

#hideMe {
    -moz-animation: cssAnimation 500ms ease-out 7s forwards;
    /* Firefox */
    -webkit-animation: cssAnimation 500ms ease-out 7s forwards;
    /* Safari and Chrome */
    -o-animation: cssAnimation 500ms ease-out 7s forwards;
    /* Opera */
    animation: cssAnimation 500ms ease-out 7s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes cssAnimation {
    from {
        top: 0;
        height:200px;
    }
    to {
        height: 0;
        top: -200px;
        overflow: hidden;
    }
}

@-webkit-keyframes cssAnimation {
    from {
        top: 0;
        height:200px;
    }
    to {
        height: 0;
        top: -200px;
    }
}


.ts_title {
    font-family: Avenir-Heavy, monospace;
    letter-spacing: -0.61pt;
    font-size: 18pt;
    line-height: 25pt;
    color: rgb(45, 41, 38);
}

.ts_message {
    font-family: Avenir-Light, monospace;
    letter-spacing: 0.17pt;
    font-size: 13pt;
    line-height: 18pt;
    color: rgb(45, 41, 38);
}


