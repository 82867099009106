.video-frame {

    max-width: 550px;
    height: 310px;
    display: flex;
    background-position: center;
    background-size: cover; 
    justify-content: center;
    align-items: center;
}

.play-button{
    position: absolute;
    display: flex;
    flex-direction: column;
}

.play-button:hover {
    transform: scale(1.02);
    overflow: hidden;
}

.video-frame:hover .play-button{
    transform: scale(1.02);
    overflow: hidden;
}