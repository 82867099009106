.cd-header-title {
    font-family: Avenir-Heavy, sans-serif !important;
    font-size: 17pt !important;
    line-height: 25pt !important;
    letter-spacing: -0.61pt !important;
    font-weight: bold !important;
    color: rgb(45 41 38) !important;
}

.cd-help-support-farmers-container {
    padding: 30px 18px;
    text-align: center;
    background-color: #edebe9;
}

.cd-support-farmers-title {
    font-family: Avenir-Heavy, sans-serif !important;
    font-size: 16pt !important;
    letter-spacing: -0.61pt !important;
    color: rgb(0, 0, 0) !important;
    line-height: 25pt !important;
    text-align: left !important;
}

.cd-header-flag {
    justify-content: end;
    text-align: right;
}

.cd-header-flag-image {
    width: 34pt;
    height: 23pt;
}

.cd-quote-container {
    padding: 0 20pt;
}

.cd-quotes-text {
    font-family: Avenir-Light, sans-serif !important;
    font-size: 18pt !important;
    line-height: 30pt !important;
    letter-spacing: -0.2pt !important;
    color: rgb(0 0 0) !important;
    text-align: left !important;
}

.cd-quotedBy-text {
    font-family: Avenir-Medium, sans-serif !important;
    font-size: 15pt !important;
    line-height: 20pt !important;
    letter-spacing: 0.21pt !important;
    color: rgb(45 41 38) !important;
    text-align: right !important;
}

.cd-position-text {
    font-family: Avenir-Medium, sans-serif !important;
    font-size: 12pt !important;
    line-height: 18pt !important;
    letter-spacing: 0.17pt !important;
    color: rgb(0 130 72) !important;
    text-align: right !important;
}

.cd-detail-text {
    font-family: Avenir-Light, sans-serif !important;
    font-size: 13pt !important;
    line-height: 22pt !important;
    letter-spacing: 0.17pt !important;
    color: rgb(45 41 38) !important;
    text-align: left !important;
}

.cd-button-center {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
}

.cd-card-description {
    font-family: Avenir-Light, sans-serif !important;
    font-size: 13pt !important;
    line-height: 18pt !important;
    letter-spacing: 0.17pt !important;
    margin-top: 5pt !important;
    color: rgb(0 0 0) !important;
}

.cd-card-title {
    font-family: Avenir-Medium, sans-serif !important;
    font-size: 15pt !important;
    line-height: 20pt !important;
    color: rgb(0 0 0) !important;
}

.cd-card-content-center {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
}

.cd-facts-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 12px 25px 12px 31px;
}

.cd-star {
    margin-right: 11pt;
    margin-top: 2px;
}

.cd-facts-title {
    font-family: Avenir-Medium, sans-serif !important;
    font-size: 12pt !important;
    letter-spacing: 0.17pt !important;
    color: rgb(0, 0, 0) !important;
    line-height: 20pt !important;
    text-align: left !important;
}
