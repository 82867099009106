.footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 258px;
    background-color: #edebe9;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 15px;
    padding-right: 15px;
}

.footer-select {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-link-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-select-icon {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.select {
    padding-right: 40px;
}

.select__selectedText,
.select__selectedText .select__selectedText--noLabel {
    border: none !important;
}

.footer-link {
    text-decoration: none;
}

.footer-term-of-use {
    margin-top: 15px;
}

.footer-disclaimer {
    font-size: 10px;
}
