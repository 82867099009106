.prod-roast-spectrum {
    position: relative;
    display: block;
    height: 27px;
    width: 100%;
    background: url('../../../images/prod_spectrum.jpg') no-repeat center center;
    background-size: 100% 80px;
    margin-top: -5px;
}

.prod-roast-needle {
    position: absolute;
    width: 25px;
    height: 100px;
    bottom: 0;
}

.spectrum-needle {
    position: absolute;
    top: 91px;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid #fff;
}

.spectrum-bar-left {
    left: -92px;
}

.spectrum-bar-left,
.spectrum-bar-right {
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 4px;
    color: #fff;
}

.spectrum-bar {
    position: absolute;
    border-top: 2px solid #fff;
    width: 100%;
    margin: 0 auto;
}

.spectrum-bar-right {
    right: -94px;
}

.spectrum-bar-wrapper {
    width: 100%;
    padding-top: 33px;
    position: relative;
    width: 57%;
    margin: -3px auto;
}
