
:root {
  --columnWidthNarrow: 343px;
  --columnWidthMedium: 500px;
  --columnWidthWide: 600px;
}


/*.wide {*/
  /*padding-left: 0.8rem;*/
  /*padding-right: 0.8rem;*/
  /*padding-top: 1.6rem;*/
/*}*/

@media (min-width: 480px) {
  .narrow {
    max-width: 343px;
  }
  .medium {
    max-width: 500px;
  }
}

@media (min-width: 768px) {
  .medium {
    max-width: 550px;
  }
  .wide {
    max-width: 550px;
  }
}

@media (min-width: 1024px) {
  .wide {
    max-width: 550px;
  }
}