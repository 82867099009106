.journey-title {
    color: rgb(45, 41, 38) !important;
    text-align: left !important;
}

.journey-description {
    font-family: Avenir-Light, sans-serif !important;
    font-size: 12pt !important;
    letter-spacing: 0.17pt !important;
    color: rgb(0, 0, 0) !important;
    line-height: 24pt !important;
    text-align: left !important;
}

.journey-date-lot-title {
    font-family: Avenir-Medium, sans-serif !important;
    font-size: 13pt !important;
    color: rgb(109, 1, 19) !important;
    line-height: 18pt !important;
    text-align: left !important;
}

.coffee-container .icon-container {
    position: absolute;
    margin-left: 1px;
    z-index: 2;
}

.coffee-content {
    margin-left: 12px;
    margin-top: -4px;
}

.coffee-container {
    margin-bottom: -80px;
}

.origin-container {
    padding-bottom: 10px;
}

.roasting-plant-container .icon-container,
.origin-container .icon-container {
    position: absolute;
    margin-left: 1px;
    margin-top: -2px;
    z-index: 2;
}

.coffee-container .icon-container img,
.roasting-plant-container .icon-container img,
.origin-container .icon-container img {
    height: 42px;
}

.country-name {
    font-weight: bold;
}

.origin-disclaimer-text {
    color: rgb(45, 41, 38) !important;
    font-family: Avenir-Light, sans-serif !important;
    font-size: 10pt !important;
    line-height: 14pt !important;
    letter-spacing: 0.13pt;
}

.content-container {
    position: relative;
    display: flex;
    padding-bottom: 20px;
}

.progress-container {
    position: absolute;
    left: 12px;
    top: 0;
    height: 100%;
    z-index: 1;
    width: 8px;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    justify-content: center;
}

.progress-bar {
    display: flex;
    height: 0%;
    background: #884539;
    width: 4px;
}

.journey-title-spacing {
    margin-left: 26px;
    padding-bottom: 9px;
}

.story-stick {
    align-items: flex-end;
    display: flex;
    flex-flow: column;
}

.sticky-social-share {
    opacity: 1;
    pointer-events: none;
}

.sticky-social-share.-viewed {
    opacity: 1;
    pointer-events: auto;
}

.sb-social-share__button .sb-icon {
    height: 24px;
    width: 24px;
}
