.detail {
    display:table ;
    height:100%;
}

.detailContent {
    display:table-cell
}

.carousel__control{
    background-color: #00000000 !important;
}

.carousel__dot__active {
    background: rgb(52, 49, 59) !important;
}


.carousel__dot {
    border-radius: 50% !important;
    width: 7pt !important;
    height: 7pt !important;
}

.sb-card-shadow {
    box-shadow: 0.1pt 0.1pt 2pt 2pt rgba(0, 0, 0, 0.10) !important;
}

.sb-card {
    border-radius: 3px !important;
}

.ca-text{
    font-family: Avenir-Light, sans-serif !important;
    font-size: 13pt !important;
    letter-spacing: 0.17pt !important;
    color: rgb(0, 0, 0) !important;
    line-height: 18pt !important;
    text-align: left !important;
}

/*.sb-card__imageBlockSmall {*/
/*    !*min-width: 120px;*!*/
/*    min-height: 120px;*/
/*    !*max-width: 150px;*!*/
/*    max-height: 150px;*/
/*}*/
